export default function useFormData(data: Record<string, unknown>) {
  const formData = new FormData()

  for (const [k, v] of Object.entries(data)) {
    const key = k
    let value = v

    if (typeof value === 'boolean') {
      value = value ? '1' : '0'
    }

    if (Array.isArray(value)) {
      value.forEach((val: string | Blob) => {
        formData.append(`${key}[]`, val)
      })
      continue
    }

    formData.append(key, value ? (value as string | Blob) : '')
  }

  return formData
}
