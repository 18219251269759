<template>
  <NuxtLoadingIndicator color="#E83754" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script lang="ts" setup>
useHead({
  bodyAttrs: {
    class: 'font-sans antialiased text-gray-600 min-h-full overflow-x-hidden',
  },
})
</script>
